import React,{useState} from 'react'
import './Message.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Join = () => {
    const [inputs, setInputs] = useState({});
    const navigate=useNavigate();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post("https://easy-gray-chameleon-tam.cyclic.app/api/join/", inputs)
      .then(response => {
         
        alert("Form Submission Successful");
        navigate('/')
        // Handle successful response here
      })
      .catch(error => {
        console.error("Submission failed:", error);
        // Handle error here
      });
  };
  

  return (
    <div className='message-main' style={{overflow:'hidden'}}>
      
        <form className='form-main' onSubmit={handleSubmit}>
        <span style={{color:'blue',textAlign:'center'}}>Submit Your Details</span>
            <input  name='name' type="text" placeholder='Full Name' required={true}  onChange={handleChange}/>
             <input name='mobileNumber' type="number"   placeholder='Enter your Mobile Number'  required={true} onChange={handleChange}/>
             <input name='email' type="email" required={true}  placeholder='Enter Your Email' onChange={handleChange}/>
             <textarea name="message"  cols="30" rows="10" placeholder='Write a Message' required={true} onChange={handleChange}></textarea>
             <input type="Submit" />
        </form>
    </div>
  )
}

export default Join