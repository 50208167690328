import React from 'react'
import './Home.css'
import {  useNavigate } from 'react-router-dom'
import arrowGif from '../utils/arrowGif.gif'


const Footer = () => {
  const navigate=useNavigate();
  const handleClick=(e)=>{
   e.preventDefault();
   navigate('/join')
  }
  return (
    <div className="footer-page">
    <div className="footer-top">
      <img className='footer-arrow-img' src={arrowGif} alt="footer-arrow" />
      <span><h2 className="footer-des1">Get Started</h2> <h3 className="footer-des2">Join The Program</h3></span>
      <button className='footer-join-btn' onClick={handleClick}> Start Earning </button>
    </div>
  </div>
  )
}

export default Footer