import React from 'react'
import './Growth.css'
import growthbanner2 from '../utils/growthbanner2.png'
import yellowVector from '../utils/yellowVector.svg'
import construction from '../utils/construction.png'

const Growth = () => {
  return (
     <div style={{overflow:'hidden',width:'100%'}}>
      <div className="growth-about-1">
        <img className='growth-img' src={growthbanner2} alt="growthbanner" />
        <h2 className='growth-message1'>We as a team provide heavy growth to our customers. Our all customers money are growing fourty percent or more in a year and it's a guranteed growth. We provide all the learnings and necessary information to our customers and support on his investment. Come and join us for fulfil your dream.</h2>
        <div className='growth-main-3'>
    <span><h1 className="growth-services">Why Choose Us</h1> <img  className='contact-img' src={yellowVector} alt="contact-heading" /></span>
            
           <div> <h2 className='growth-message2'>Experienced and skilled team of professionals. Quality workmanship and attention to detail. Commitment to timely completion of projects.</h2></div>
            
       <div className='growth-img-main'> <img className='services-img' src={construction} alt="construction" />
       </div>
        </div>
      </div>
     </div>
  )
}

export default Growth