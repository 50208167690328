import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import stcpllogo from '../utils/stcpllogo.jpg'

const Navbar = () => {
  return (
    <div className='navbar_Main' >
      
        <Link className='navbar-link' to='/'>
             <img className='nav-img' src={stcpllogo} alt=''/>
        </Link>
        <Link   to='/growth'>GROWTH</Link>
        <Link   to='/message'>MESSAGE-US</Link>
    </div>
  )
}

export default Navbar