import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Growth from '../pages/Growth'
import Message from '../pages/Message'
import Join from '../pages/Join'
import Notfound from '../pages/Notfound'

const Allroutes = () => {
  return (
    <div>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/growth' element={<Growth/>}/>
            <Route path='/message' element={<Message/>}/>
            <Route path='/join' element={<Join/>}/>
            <Route path='*' element={<Notfound/>}/>
        </Routes>
    </div>
  )
}

export default Allroutes