import React from 'react'
import './Home.css'
 
import yellowVector from '../utils/yellowVector.svg'
 
 
import homebanner1 from '../utils/homebanner1.png'

const Home = () => {
  return (
    <div style={{overflowX:'hidden'}}>
        
       <h1 className="home-about-1">HAR GHAR SARKARI NAUKRI</h1>
       
    <div className='home-main'>
        
        <div className='home-main-1'>
            <img className='home-banner' src={homebanner1} alt="trading-banner" />
        </div>
        <div className='home-main-2'>
            <h1 className='home-about'>WELCOME TO STCPL</h1> 
            <h2 className="current-offer-1">At Shailputri, we are dedicated to empowering individuals with the knowledge and tools to thrive in the world of trading. Whether you're seeking lucrative investments or eager to learn the art of trading, we have the expertise to guide you. Take the first step towards financial growth and empowerment. Join us at STCPL and turn your dreams into financial realities.   </h2>
        </div>
        
    </div>
    <div className='home-main-3'>
    <span><h1 className="services">SERVICES</h1> <img  className='contact-img' src={yellowVector} alt="contact-heading" /></span>
           <h2 className='services1'>We're a dynamic community of 50+ individuals. We don't just want customers – we want partners. We're here to share ideas, profits, and growth. We believe in collaboration, offering you trading insights for mutual success. By joining us, you're not just a customer; you're a participant in our shared journey. We're all about collective growth and shared rewards. Come be a partner, not just a bystander, in a thriving community that's redefining trading.  </h2>
       <div className='services-img-main'> <img className='services-img' src="https://m.economictimes.com/thumb/msid-88204849,width-1200,height-900,resizemode-4,imgsize-109797/algo-trading.jpg" alt="trading" />
       </div>
        </div>

        <div className="home-main-4">
          <span><h1 className="services">CONTACTS</h1> <img  className='contact-img' src={yellowVector} alt="contact-heading" /></span>
         <div className="contact-details">
          <div className="office-address">
            <span><h1>Office Address</h1><h3>Opposite UCO Bank, Nawagarhi, District- Munger, Bihar, India, Pincode- 811211</h3></span> 
          </div>
          <div className="contact-number">
            <label><h1>Contact Details</h1><h3>Contact no. - 8340347972 </h3><h3>Email - suraj@shailputri.com</h3></label>
          </div>
          </div>
        </div>
        
    </div>
  )
}

export default Home