 import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Allroutes from './components/Allroutes'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import './App.css'
 
 const App = () => {
   return (
     <div>
      <BrowserRouter>
      <Navbar/>
      <Allroutes/>
      <Footer/>
      </BrowserRouter>
      </div>
   )
 }
 
 export default App